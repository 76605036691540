import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import _ from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

  const Hero = styled.div`
    position: relative;
    height: 0;
    top: 50px;
    padding-bottom: 30%;
    margin-bottom: 50px;
  `

  const Image = styled(Img)`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `

export const BlogPostTemplate = ({
  content,
  categories,
  tags,
  title,
  date,
  featuredMedia,
}) => {
  const heroImg = _.get(featuredMedia, 'localFile.childImageSharp.fluid');

  return (
    <section className="section">

      <div className="container content">
        {heroImg && (
          <Hero>
            <Image fluid={heroImg} style={{position: `absolute`}} />
          </Hero>
        )}
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light m-t-xl">
              {title}
            </h1>
            <p>
              {date}
            </p>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div style={{ marginTop: `4rem` }}>
              {categories && categories.length ? (
                <div>
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {categories.map(category => (
                      <li key={`${category.slug}cat`}>
                        <Link to={`/categories/${category.slug}/`} dangerouslySetInnerHTML={{ __html: category.name }} />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {tags && tags.length ? (
                <div>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={`${tag.slug}tag`}>
                        <Link to={`/tags/${tag.slug}/`} dangerouslySetInnerHTML={{ __html: tag.name }} />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
)}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data
  const { title: siteTitle } = data.site.siteMetadata
  return (
    <Layout>
      <Helmet title={`${post.title} | ${siteTitle}`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
        featuredMedia={post.featured_media}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
    }
  }
`
